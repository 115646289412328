<template>
    <v-container fluid class="pa-0 mt-10 pb-5">
        <v-col cols="12" lg="10" md="12" class="mx-auto">
            <v-row>
                <v-layout class="bottom-flower">
                    <v-img
                        class="shrink bottom-flower-img"
                        height="200"
                        width="230"
                        contain
                        src="../assets/bottom_flower.png"
                    />
                </v-layout>

                <v-col
                    cols="12"
                    md="10"
                    lg="12"
                    class="mx-auto pa-0 footer-content pa-5 pa-md-0"
                >
                    <v-layout>
                        <v-col class="pa-0">
                            <v-layout column>
                                <v-layout>
                                    <v-img
                                        @click="
                                            openLink(
                                                'https://www.instagram.com/miedmoldova_official/'
                                            )
                                        "
                                        class="shrink instagram-icon"
                                        width="44"
                                        contain
                                        src="../assets/instagram_icon.png"
                                    />
                                    <v-img
                                        @click="
                                            openLink(
                                                'https://www.facebook.com/MIED.official/'
                                            )
                                        "
                                        class="shrink facebook-icon"
                                        width="44"
                                        contain
                                        src="../assets/facebook_icon.png"
                                    />
                                </v-layout>
                                <v-img
                                    class="shrink social-icons-background"
                                    width="86"
                                    contain
                                    src="../assets/social_icons_background.png"
                                />
                            </v-layout>
                        </v-col>

                        <v-col class="contact-info">
                            <v-layout column align-end>
                                <div class="address-info">
                                    {{ "Republica Moldova, Chișinau," }}
                                </div>
                                <div class="address-info">
                                    {{ "str. Banulescu-Bodoni, 41" }}
                                </div>
                                <div class="email-info">
                                    {{ "mied.official@gmail.com" }}
                                </div>
                            </v-layout>
                        </v-col>
                    </v-layout>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "Header",
    methods: {
        openLink(url) {
            window.open(url);
        }
    }
};
</script>

<style scoped>
.footer-content {
    z-index: 1;
}
.social-icons-background {
    margin-top: -18px;
}
.instagram-icon {
    z-index: 1;
    cursor: pointer;
}
.facebook-icon {
    z-index: 1;
    cursor: pointer;
    margin-left: -1px;
}
.contact-info {
    z-index: 1;
}
.address-info {
    font-family: "rockeb";
    font-size: 14px;
    line-height: 16px;
    color: white;
}
.email-info {
    font-family: "rockeb";
    font-size: 14px;
    line-height: 16px;
    color: #b4813d;
}
.bottom-flower {
    position: absolute;
    bottom: 0;
    right: 30%;
    overflow: hidden;
    z-index: 0;
}
.bottom-flower-img {
    margin-bottom: -10px;
    z-index: 0;
}
</style>
