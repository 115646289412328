var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 content-container mx-auto mt-10",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"left-flower"},[_c('v-img',{staticClass:"shrink left-flower-img",attrs:{"height":"280","width":"180","contain":"","src":require("../assets/left_flower.png")}})],1),_c('v-layout',{staticClass:"right-flower"},[_c('v-img',{staticClass:"shrink right-flower-img",attrs:{"height":"280","width":"180","contain":"","src":require("../assets/right_flower.png")}})],1),_c('v-col',{staticClass:"mx-auto pa-0",staticStyle:{"z-index":"1"},attrs:{"cols":"12","sm":"12","lg":"10","md":"10"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"z-index":"1"},attrs:{"cols":"10","sm":"11","md":"4","align-self":"center"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"align-self-center align-self-md-start"},[_c('v-img',{staticClass:"shrink",attrs:{"width":"80","contain":"","src":require("../assets/bee_logo.png")}})],1),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
                            ratio: 1,
                            minFontSize: '25',
                            maxFontSize: '38px',
                            delay: 0
                        }),expression:"{\n                            ratio: 1,\n                            minFontSize: '25',\n                            maxFontSize: '38px',\n                            delay: 0\n                        }"}],staticClass:"left-block-title mt-6 text-center text-md-left"},[_vm._v(" "+_vm._s("Mied - filozofia placerii, nu doar o bautura! ")+" ")]),_c('v-col',{staticClass:"mx-auto pa-0 mt-2",attrs:{"cols":"12","sm":"12","md":"12","align":"center","justify":"center"}},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
                                ratio: 1.6,
                                minFontSize: '14px',
                                maxFontSize: '16px',
                                delay: 0
                            }),expression:"{\n                                ratio: 1.6,\n                                minFontSize: '14px',\n                                maxFontSize: '16px',\n                                delay: 0\n                            }"}],staticClass:"left-block-description text-center text-md-left"},[_vm._v(" "+_vm._s("Efervescent, care este fabricat în mod traditional prin fermentarea naturala a mierii.")+" ")])])],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","md":"4"}},[_c('v-layout',{staticClass:"wine-bottle",attrs:{"fill-height":""}},[_c('v-img',{attrs:{"contain":"","src":require("../assets/wine_bottle.jpg")}})],1)],1),_c('v-col',{staticClass:"my-auto",staticStyle:{"z-index":"1"},attrs:{"cols":"12","md":"4"}},[_c('v-layout',{staticClass:"information-container",attrs:{"column":""}},[_c('v-col',{staticClass:"mx-auto pl-0",attrs:{"cols":"12","md":"12","sm":"8","align":"center"}},[_c('div',{staticClass:"right-block-title text-center text-md-left mt-5"},[_vm._v(" "+_vm._s("Bucura-te de magia mierii efervescente!")+" ")])]),_c('v-col',{staticClass:"mx-auto pl-4 pr-4 pl-md-0",attrs:{"cols":"12","md":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"right-block-description  text-center text-md-left"},[_vm._v(" "+_vm._s("Bautura slab alcoolica obtinută prin fermentarea mierii de albine. Miedul se obtine din fermentarea mierii amestecate cu apa calda. Mied era o bautura binecunoscuta in antichitate: grecii ii spunea Hidromel - cuvintele care arata elementele ei: mierea si apa. Slavii vechi ii spuneau medu, medovuha, iar romanii - mied.")+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }