<template>
    <v-app>
        <v-main>
            <v-container fluid class="content-container pa-0" fill-height>
                <Header />

                <Content />

                <Footer />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

export default {
    name: "App",
    components: {
        Header,
        Content,
        Footer
    }
};
</script>

<style scoped>
@font-face {
    font-family: "rockeb";
    src: local("rockeb"), url(./fonts/rockeb.ttf) format("truetype");
}
@font-face {
    font-family: "rockwell";
    src: local("rockwell"), url(./fonts/rock.ttf) format("truetype");
}
@font-face {
    font-family: "rockb";
    src: local("rockb"), url(./fonts/rockb.ttf) format("truetype");
}
.content-container {
    background: url(./assets/background.png);
    background-color: black;
    background-position: center;
    background-size: contain;
}
</style>
