<template>
    <v-container fluid class="pa-0 content-container mx-auto mt-10">
        <v-layout class="left-flower">
            <v-img
                class="shrink left-flower-img"
                height="280"
                width="180"
                contain
                src="../assets/left_flower.png"
            />
        </v-layout>

        <v-layout class="right-flower">
            <v-img
                class="shrink right-flower-img"
                height="280"
                width="180"
                contain
                src="../assets/right_flower.png"
            />
        </v-layout>

        <v-col
            cols="12"
            sm="12"
            lg="10"
            md="10"
            class="mx-auto pa-0"
            style="z-index: 1"
        >
            <v-row justify="center">
                <v-col
                    cols="10"
                    sm="11"
                    md="4"
                    align-self="center"
                    style="z-index: 1"
                >
                    <v-layout column>
                        <v-layout class="align-self-center align-self-md-start">
                            <v-img
                                class="shrink"
                                width="80"
                                contain
                                src="../assets/bee_logo.png"
                            />
                        </v-layout>

                        <div
                            class="left-block-title mt-6 text-center text-md-left"
                            v-resize-text="{
                                ratio: 1,
                                minFontSize: '25',
                                maxFontSize: '38px',
                                delay: 0
                            }"
                        >
                            {{
                                "Mied - filozofia placerii, nu doar o bautura! "
                            }}
                        </div>

                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            align="center"
                            justify="center"
                            class="mx-auto pa-0 mt-2"
                        >
                            <div
                                class="left-block-description text-center text-md-left"
                                v-resize-text="{
                                    ratio: 1.6,
                                    minFontSize: '14px',
                                    maxFontSize: '16px',
                                    delay: 0
                                }"
                            >
                                {{
                                    "Efervescent, care este fabricat în mod traditional prin fermentarea naturala a mierii."
                                }}
                            </div>
                        </v-col>
                    </v-layout>
                </v-col>

                <v-col cols="6" md="4" class="pa-0">
                    <v-layout fill-height class="wine-bottle">
                        <v-img contain src="../assets/wine_bottle.jpg" />
                    </v-layout>
                </v-col>

                <v-col cols="12" md="4" class="my-auto" style="z-index: 1">
                    <v-layout column class="information-container">
                        <v-col
                            cols="12"
                            md="12"
                            sm="8"
                            class="mx-auto pl-0"
                            align="center"
                        >
                            <div
                                class="right-block-title text-center text-md-left mt-5"
                            >
                                {{ "Bucura-te de magia mierii efervescente!" }}
                            </div>
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            class="mx-auto pl-4 pr-4 pl-md-0"
                            align="center"
                        >
                            <div
                                class="right-block-description  text-center text-md-left"
                            >
                                {{
                                    "Bautura slab alcoolica obtinută prin fermentarea mierii de albine. Miedul se obtine din fermentarea mierii amestecate cu apa calda. Mied era o bautura binecunoscuta in antichitate: grecii ii spunea Hidromel - cuvintele care arata elementele ei: mierea si apa. Slavii vechi ii spuneau medu, medovuha, iar romanii - mied."
                                }}
                            </div>
                        </v-col>
                    </v-layout>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import ResizeText from "vue-resize-text";
export default {
    directives: {
        ResizeText
    }
};
</script>

<style scoped>
.content-container {
    position: relative;
    overflow: hidden;
}
.wine-bottle {
    max-height: 900px;
    margin-left: -700px;
    margin-right: -700px;
    margin-top: 100px;
    z-index: 0;
}
@media screen and (max-width: 960px) {
    .wine-bottle {
        max-height: 900px;
        margin-left: -700px;
        margin-right: -700px;
        margin-top: 0px;
    }
    .information-container {
        margin-top: -200px;
    }
}
.left-block-title {
    font-family: "rockeb";
    font-size: 38px;
    color: white;
}
.left-block-description {
    font-family: "rockwell";
    font-size: 16px;
    color: white;
}
.right-block-title {
    font-family: "rockwell";
    font-size: 25px;
    color: #b4813d;
}
.right-block-description {
    font-family: "rockwell";
    font-size: 16px;
    color: white;
}
.left-flower {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
}
.left-flower-img {
    margin-left: -25px;
    z-index: 0;
}
.right-flower {
    position: absolute;
    align-items: start;
    right: 0;
    z-index: 1;
}
.right-flower-img {
    z-index: 0;
    margin-right: -25px;
}
.bee_logo {
    align-self: center;
}
.information_container {
    z-index: 1;
}
</style>
